// This allows paragraphs in summary blocks on the posts index page to render
// sensibly.  (IMOSHO)

// .home[posts] {
//     .summary {
//         .content {
//             p {
//                 display: block;
//             }
//         }
//     }
// }
